import User from '@client/entities/User';
import BaseService from '@client/services/BaseService';
import { CLIENT_API, LOGOUT_API } from '@config/environment';

const service = new BaseService();

export function login(creds: any): Promise<any> {
  const api = `${CLIENT_API}/login`;

  return service.post(api, { data: creds });
}

export const logout = async () => {
  const response = await service.post(`${CLIENT_API}${LOGOUT_API}`);
  return response;
};

export function forgotPasswordRequestToken({ email }: User) {
  const api = `${CLIENT_API}/send-link`;
  return service.post(api, { data: { email } });
}

export function forgotPasswordCheckToken({ token }: User) {
  const api = `${CLIENT_API}/check-token`;
  return service.post(api, { data: { token } });
}

export function forgotPasswordUpdate({ token, password }: User) {
  const api = `${CLIENT_API}/update-pass`;
  return service.post(api, { data: { token, password } });
}
