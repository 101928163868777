import { forwardRef } from 'react';

const Text: React.FC<any> = forwardRef(
  (
    {
      as: Component = 'p',
      children,
      lineHeight,
      border,
      borderRadius,
      color,
      overflow,
      margin,
      padding,
      width,
      textAlign,
      display,
      position,
      top,
      left,
      right,
      bottom,
      fontWeight,
      fontSize,
      style,
      textTransform,
      ...props
    },
    ref,
  ) => {
    return (
      <>
        <Component
          {...props}
          ref={ref}
          style={{
            border,
            borderRadius,
            lineHeight,
            color,
            overflow,
            textTransform,
            margin,
            padding,
            width,
            textAlign,
            display,
            position,
            top,
            left,
            fontWeight,
            right,
            fontSize,
            bottom,
            ...style,
          }}
        >
          {children}
        </Component>
      </>
    );
  },
);

export default Text;
