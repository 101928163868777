export default class ErrorService extends Error {
  public statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;

    if (Error.captureStackTrace != null) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
