export class ResponseCode {
  static SUCCESS: number = 200;
  static NO_CONTENT: number = 201;
  static BAD_REQUEST: number = 400;
  static FORBIDDEN: number = 403;
  static UNAUTHORISED: number = 401;
  static NOT_FOUND: number = 404;
  static INTERNAL_SERVER_ERROR: number = 500;
  static DEFAULT: number = -1;
  static CONNECT_TIMEOUT: number = -2;
  static CANCEL: number = -3;
  static RECEIVE_TIMEOUT: number = -4;
  static SEND_TIMEOUT: number = -5;
  static CACHE_ERROR: number = -6;
  static NO_INTERNET_CONNECTION: number = -7;
}

export class ResponseMessage {
  static SUCCESS: string = 'success';
  static NO_CONTENT: string = 'success with no content';
  static BAD_REQUEST: string = 'Bad request, try again later';
  static FORBIDDEN: string = 'forbidden request, try again later';
  static UNAUTHORISED: string = 'user is unauthorised, try again later';
  static NOT_FOUND: string = 'URL is not found, try again later';
  static INTERNAL_SERVER_ERROR: string = 'Something wrong, try again later';

  static DEFAULT: string = 'something wrong, try again later';
  static CONNECT_TIMEOUT: string = 'connection timeout, try again later';
  static CANCEL: string = 'request was cancelled, try again later';
  static RECEIVE_TIMEOUT: string = 'time out error, try again later';
  static SEND_TIMEOUT: string = 'time out error, try again later';
  static CACHE_ERROR: string = 'Cache error, try again later';
  static NO_INTERNET_CONNECTION: string =
    'Please check your internet connection';
}

export class ApiInternalStatus {
  static SUCCESS: number = 0;
  static FAILURE: number = 1;
}
