import { ResponseCode, ResponseMessage } from '@config/constant';
import axios, { AxiosError, AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import ErrorService from './ErrorService';

export default class BaseService {
  public controller: any = axios.CancelToken.source();

  protected method: any = {
    post: 'POST',
    get: 'GET',
    put: 'PUT',
    delete: 'DELETE',
  };

  public get(api: string = '', config: any = {}): Promise<any> {
    return this.request(this.method.get, api, config);
  }

  public post(api: string = '', config: any = {}): Promise<any> {
    return this.request(this.method.post, api, config);
  }

  public put(api: string = '', config: any = {}): Promise<any> {
    return this.request(this.method.put, api, config);
  }

  public delete(api: string = '', config: any = {}): Promise<any> {
    return this.request(this.method.delete, api, config);
  }

  private urlEncode(params: object): string {
    const encode = (params: object, nesting: string = '') => {
      const pairs: any = Object.entries(params).map(([key, value]) => {
        if (typeof value === 'object') {
          return encode(value, `${nesting}${encodeURIComponent(key)}=`);
        }

        return [
          nesting + encodeURIComponent(key),
          encodeURIComponent(value),
        ].join('=');
      });

      return pairs.join('&');
    };

    return encode(params).replace(/&\s*$/, '');
  }

  // private removeEmpty(param: any): object {
  //   for (const key in param) {
  //     if (
  //       param[key] === null ||
  //       param[key] === undefined ||
  //       param[key] === ""
  //     ) {
  //       delete param[key];
  //     }
  //   }

  //   return param;
  // }

  private handleResponse(response: AxiosResponse) {
    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response.data.message);
    }
  }

  private async handleError(error: any) {
    if (error.statusCode === 401 || error.statusCode === 403) {
      window.location.href = '/';
    }

    return {
      data: null,
      status: error.statusCode,
      message: error.message,
    };
  }

  private async getCsrfToken() {
    const api = '/api/csrf';
    const response = await fetch(api).then((res) => res.json());
    return { 'X-CSRF-Token': response.CSRFToken };
  }

  private async request(
    method: string = '',
    url: string = '',
    config: any,
  ): Promise<any> {
    if (['POST', 'PUT', 'DELETE'].includes(method)) {
      const token = await this.getCsrfToken();
      config = { ...config, headers: { ...config.headers, ...token } };
    }

    return axios({
      ...config,
      method,
      url,
      cancelToken: this.controller.token,
      paramsSerializer: this.urlEncode,
      timeout: 600000,
      headers: { 'Content-Type': 'application/json', ...config.headers },
    })
      .then((response: AxiosResponse) => {
        return this.handleResponse(response);
      })
      .catch((e: AxiosError) => {
        if (e instanceof ErrorService) {
          return this.handleError(e);
        }

        switch (e.response?.status) {
          case ResponseCode.BAD_REQUEST:
            // prettier-ignore
            return this.handleError(new ErrorService(ResponseMessage.BAD_REQUEST, ResponseCode.BAD_REQUEST));
          case ResponseCode.FORBIDDEN:
            // prettier-ignore
            return this.handleError(new ErrorService(ResponseMessage.FORBIDDEN, ResponseCode.FORBIDDEN));
          case ResponseCode.UNAUTHORISED:
            // prettier-ignore
            return this.handleError(new ErrorService(ResponseMessage.UNAUTHORISED, ResponseCode.UNAUTHORISED));
          case ResponseCode.NOT_FOUND:
            // prettier-ignore
            return this.handleError(new ErrorService(ResponseMessage.NOT_FOUND, ResponseCode.NOT_FOUND));
          case ResponseCode.INTERNAL_SERVER_ERROR:
            // prettier-ignore
            return this.handleError(new ErrorService(ResponseMessage.INTERNAL_SERVER_ERROR, ResponseCode.INTERNAL_SERVER_ERROR));
          default:
            // prettier-ignore
            return this.handleError(new ErrorService(ResponseMessage.FORBIDDEN, ResponseCode.FORBIDDEN));
        }
      });
  }

  public abortRequest() {
    this.controller.cancel('Operation canceled by the user.');
    this.controller = axios.CancelToken.source();
  }
}
